.personal {
    &__body {
       height: 100%;
    }
    &__list {
       display: flex;
       justify-content: space-around;
       align-self: center;
       flex-wrap: wrap;
       list-style: none;
    }
 }
 
 .title {}

 .personal-item {
    width: 300px;
    padding: 50px 0;
    transition: all 0.2s ease-out;
     @media (max-width: 450px) {
       width: auto;
     }
    &__photo {
       display: block;
       height: 200px;
       width: 200px;
       margin: 0 auto;
       object-position: top;
       object-fit: cover;
       border-radius: 50%;
    }
    &__description {
       margin-top: 20px;
       &_name {
          text-align: center;
          font-weight: bold;
       }
       &_position {
          text-align: center;
          padding-top: 5px;
       }
    }
    &:hover {
       box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.2) ;
    }
 }