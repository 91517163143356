.libraries-item {
    &__body {}
}

.libraries-item-title {
    &__text {}
}

.libraries-item-info {
    &__body {
        display: flex;
    }
}

.libraries-item-info-container {
    width: 500px;
    &__title {
        text-align: left;
        font-weight: bold;
    }
    &__block {
        line-height: 1.3;
        &_heading {
            font-weight: bold;
            font-size: 18px;
        }
        &_address {
            margin-top: 20px;
        }
        &_worktime {
            margin-top: 20px;
        }
        &_phone {
            margin-top: 20px;
        }
    }
}

.libraries-item-history {
    margin-top: 20px;
    &__body {
        line-height: 1.3;
        &_text {}
    }
}

.libraries-item-images {
    margin-top: 30px;
    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 800px) {
            flex-wrap: wrap;
        }
        &_img {
            max-width: 350px;
            border-radius: 5px;
            @media (max-width: $md1 + 'px') {
                max-width: 320px;
            }
            @media (max-width: 1000px) {
                max-width: 290px;
            }
            @media (max-width: 910px) {
                max-width: 250px;
            }
            @media (max-width: 800px) {
                max-width: 100%;
                margin: 10px auto;
            }
        }
    }
}

.libraries-item-map {
    &__body {}
}