.no-match {
  max-width: 100%;
  height: 100%;
}

.no-match-gif {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  @media (max-width: 900px) {
    margin-top: 70px;
  }
  &__img {
  }
}

.no-match-content {
  padding: 30px 0;
  &__title {
    font-family: 'FuturaPT';
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    @media (max-width: $md3 + 'px') {

    }
  }
  &__subtitle {
    font-size: 20px;
    text-align: center;
    &_link {
      display: inline-block;
      margin-left: 10px;
      color: #ff9902;
      cursor: pointer;
      border-bottom: 1px solid #000;
    }
  }
}