.clubs {}

.clubs-body {}

.clubs-links {
    display: flex;
    @media (max-width: 920px) {
        flex-wrap: wrap;
    }
}
.clubs-shedule {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 30px 0;
    @media (max-width: 920px) {
        margin: 15px 0;
        width: 500px;
        justify-content: space-between;
    }
    @media (max-width: 540px) {
        width: 100%;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }
    &__document {
        margin-left: 40px;
        border: 2px solid #000;
        @media (max-width: 380px) {
            margin-left: 10px;
        }
        &_link {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            width: 130px;
            height: 100%;
        }
        &_text {
            font-size: 16px;
        }
    }
}

.genre-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(max-width: $md1 + 'px') {
        justify-content: space-around;
    }
    @media(max-width: 900px) {
        justify-content: space-between;
    }
    @media(max-width: 580px) {
        justify-content: center;
    }
    &__item {}
}

.genre-item {
    max-width: 370px;
    height: 465px;
    border: 1px solid #e9e9e9;
    margin-bottom: 50px;
    font-size: 20px;
    &:hover {
        box-shadow: 0 0 15px 1px rgba(0,0,0,.3);
    }
    @media(max-width: $md1 + 'px') {
        max-width: 400px;
    }
    @media(max-width: 860px) {
        max-width: 350px;
    }
    @media(max-width: 760px) {
        max-width: 320px;
    }
    @media(max-width: 760px) {
        max-width: 300px;
    }
    @media(max-width: 660px) {
        max-width: 270px;
        height: 400px;
    }
    @media(max-width: 600px) {
        max-width: 260px;
        height: 400px;
    }
    @media(max-width: 580px) {
        max-width: 400px;
        height: 500px;
    }
    @media(max-width: 440px) {
        max-width: 370px;
        height: 465px;
    }
    &__body {}
    &__head {
        margin: 10px;
        position: relative;
        &_img {
            width: 100%;
            height: 270px;
            @media(max-width: 660px) {
                height: 200px;
            }
            @media(max-width: 580px) {
                height: 300px;
            }
            @media(max-width: 440px) {
                height: 270px;
            }
        }
    }
}

.genre-item {
    &__info {
        margin: 10px 0 0 10px;
        &_title {
            color: #000;
            font-size: 20px;
            font-weight: bold;
        }
        &_content {}
    }
}

.genre-item-content-block {
    color: #000;
    display: flex;
    align-items: flex-end;
    padding-top: 7px;
    font-size: 17px;
    &__icon {
        &_ico {
            font-size: 15px;
            color: #ff9902;
        }
    }
    &__clubs-count {}
    &__age {
        padding-left: 10px;
    }
    &__gender {
        padding-left: 10px;
    }
    &__level {
        padding-left: 10px;
    }
}

.genre-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &__text {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }
    &:hover {
        opacity: 1;
    }
}