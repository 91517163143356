.events {
    &__body {
        line-height: 1.3;
        &_title {}
    }

    &__month {
        margin: 50px 0;
        &_title {}
    }
}

.events-content {}

.events-list {}


// EventsItem
.events-item {
    margin: 0 70px 100px 70px;
    position: relative;
    @media (max-width: 1000px) {
        margin: 0 30px 100px 30px;
    }
    @media (max-width: $md2 + 'px') {
        margin: 0 10px 100px 10px;
    }
    @media (max-width: 700px) {
        margin: 0 0 70px 0;
    }
    @media (max-width: 420px) {
        margin-top: 50px;
    }
    &__body {
        display: flex;
        @media (max-width: 700px) {
            display: block;
        }
    }
} 

.events-date {
    align-self: center;
    width: 50px;
    text-align: center;
    @media (max-width: 700px) {
        border-right: 3px solid #000000;
    }
    @media (max-width: 630px) {
        width: 40px
    }
    @media (max-width: 520px) {
        width: 100%;
        border-right: none;
    }
    &__body {
        width: 100%;
        margin-right: 30px;
        @media (max-width: 700px) {
            width: 0;
        }
        @media (max-width: 520px) {
            width: 100%;
            border-bottom: 3px solid black;
        }
        &_day {
            @media (max-width: 520px) {
                display: none;
            }
        }
        &_number {
            font-size: 30px;
            font-weight: bold;
        }
    }
}

.events-main {
    display: flex;
    @media (max-width: 420px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.events-info {
    border-left: 3px solid;
    padding: 0 30px;
    @media (max-width: 750px) {
        padding: 0  10px;
    }
    @media (max-width: 700px) {
        border-left: none;
        padding: 0;
    }
    @media (max-width: 520px) {
        border-left: none;
    }
    &__body {}
}

.events-heading {
    @media (max-width: 700px) {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-right: 15px;
        margin-top: 20px;
    }
    @media (max-width: 520px) {
        display: block;
        padding-right: 0;
        width: calc(100% - 110px);
    }

}

.events-info-date {
    margin-top: 30px;
    @media (max-width: $md1 + 'px') {
        margin-top: 10px;
    }
    &__date {
        &_text {}
    }
}

.events-info-title {
    margin-top: 30px;
    @media (max-width: $md1 + 'px') {
        margin-top: 0;
    }
    &__title {
        @media (max-width: 700px) {
            position: absolute;
            top: -3px;
            right: 0;
            width: 90%;
        }
        @media (max-width: 520px) {
            position: static;
            width: 100%;
        }
        &_text {
            font-size: 25px;
            font-weight: bold;
        }
    }
}

.events-info-place {
    margin-top: 20px;
    @media (max-width: 850px) {
        margin-top: 10px;
    }
    @media (max-width: 700px) {
        margin-right: 30px;
    }
    &__text {
        font-weight: lighter;
        font-style: italic;
    }
}

.events-info-description {
    &__body {
        margin-top: 30px;
        @media (max-width: 850px) {
            margin-top: 10px;
        }
        @media (max-width: 700px) {
            padding-right: 10px;
        }
        @media (max-width: 520px) {
            margin-top: 40px;
        }
        @media (max-width: 420px) {
            margin-top: 20px;
        }
        &_text {}
    }
}

.events-info-cost {
    text-align: center;
    margin-top: 50px;
    @media (max-width: 850px) {
        margin-top: 10px;
    }
    @media (max-width: 420px) {
        margin-top: 15px;
    }
    @media (max-width: 520px) {
        margin-top: 25px;
    }
    &__text {
        font-weight: bold;
    }
}

.events-image {
    align-self: center;
    @media (max-width: 520px) {
        position: absolute;
        top: 40px;
        right: 0;
    }
    @media (max-width: 420px) {
        position: static;
    }
    &__img {
        max-width: 250px;
        box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
        @media (max-width: 750px) {
            width: 200px;
            box-shadow: none;
        }
        @media (max-width: 700px) {
            width: 150px;
            margin-top: 20px;
        }
        @media (max-width: 520px) {
            width: 100px;
        }
        @media (max-width: 420px) {
            width: 250px;
        }
    }
}