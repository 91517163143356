.clubs-list {
    &__body {

    }
    &__list {}
}

.clubs-list-item {
    &__body {}
}

.club-main-content-body {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 15px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #ff9902;
    }
    @media (max-width: $md1 + 'px') {
        max-width: 1020px;
    }
    @media (max-width: 992px) {
        display: block;
        max-width: 100%;
        padding: 50px 0;
        &::after {
            bottom: 10px;
            width: 10px;
            height: 10px;
        }
    }
}

.club-registration-block {
    font-family: 'FuturaPT';
    font-weight: 300;
    margin-top: 40px;
    background: #f6f6f6;
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    @media (max-width: 992px) {
        margin-top: 0;
    }
    &__body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 25px;
        padding: 20px 20px;
        @media (max-width: 630px) {
            font-size: 20px;
        }
        @media (max-width: 390px) {
            display: block;
            padding: 10px 20px;
        }
        &_title {}
    }
    &__info {
        display: flex;
        align-items: flex-end;
        &_link {
            font-weight: bold;
            color: #ff9902;
            text-shadow: 1px 0px 1px #000;
            margin-left: 10px;
            @media (max-width: 390px) {
                margin-left: 0;
            }
        }
        &_code {
            margin-left: 10px;
        }
    }
}

.club-main-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex:0 0 70%;
    @media (max-width: 992px) {
        padding: 0 70px;
    }
    @media (max-width: $md2 + 'px') {
        padding: 0 20px;
    }
}

.club-shedule {
    margin-top: 20px;
    padding-right: 30px;
    @media (max-width: 992px) {
        padding-right: 0;
    }
    &__body {}
    &__title {
        &_text {
            font-family: 'FuturaPT';
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 10px;
        }
    }
    &__info {
        max-width: 400px;
        &_text {
            line-height: 1.4;
        }
    }
}

.club-poster-block {
    display: flex;
    width: 100%;
}


.club-heading {
    padding-right: 30px;
    @media (max-width: 992px) {
        padding-right: 0;
    }
    &__club {
        &_text {
            font-family: 'FuturaPT';
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 10px;
        }
    }
    &__group {
        &_text {
            font-family: 'FuturaPT';
            font-weight: 500;
            font-size: 30px;
            padding-bottom: 10px;
        }
    }
    &__age {
        &_text {
            font-size: 25px;
            padding-bottom: 10px;
        }
    }
    &__info {
        &_text {}
    }
}

.club-poster {
    flex: 0 0 30%;
    background: #6fa89b;
    box-shadow: 0 0 15px 1px rgba(0,0,0,.3);
    border-radius: 40px;
    height: 500px;
    min-width: 100%;
    @media (max-width: 992px) {
        height: auto;
        margin-top: 50px;
        border-radius: 0;
        box-shadow: none;
    }
    &__body {
        height: calc(100% - 40px);
        margin: 20px;
        border: 3px solid #fff;
        border-radius: 30px;
        @media (max-width: 992px) {
            border: none;
            margin: 10px 15px;
        }
        @media (max-width: 630px) {
            margin: 0 10px;
        }
    }
    &__content {
        margin: 0 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        @media (max-width: 992px) {
            margin: 0;
            flex-direction: row;
            align-items: flex-start;
            padding: 20px 0;
        }
    }
}

.poster-item {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 992px) {
        display: block;
        margin-top: 0;
        flex: 25%;
    }
    &__icon {
        @media (max-width: 992px) {
            display: flex;
            justify-content: center;
        }
        &_ico {
            font-size: 30px;
            @media (max-width: 630px) {
                font-size: 20px;
            }
        }
    }
    &__text {
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
        @media (max-width: 992px) {
            margin-top: 20px;
        }
        @media (max-width: 630px) {
            font-size: 15px;
        }
    }
}


.club-manager {
    margin-top: 30px;
    padding-right: 30px;
    @media (max-width: $md2 + 'px') {
        padding-right: 0;
    }
    &__body {
        display: flex;
        @media (max-width: $md3 + 'px') {
            align-items: center;
        }
        @media (max-width: 580px) {
            display: block;
        }
    }
    &__summary {
        margin-left: 30px;
        @media (max-width: 580px) {
            margin-left: 0;
        }
    }
    &__manage {
        display: flex;
        flex-direction: column;
        &_title {
            font-family: 'FuturaPT';
            font-weight: 500;
            font-size: 30px;
        }
        &_name {
            font-size: 25px;
        }
        &_summary {
            margin-top: 20px;
        }
    }
    &__photo {
        display: flex;
        justify-content: flex-start;
        height: 200px;
        @media (max-width: 580px) {
            height: 170px;
            margin-bottom: 10px;
        }
        @media (max-width: 420px) {
            justify-content: center;
        }
        &_img {
            border-radius: 50%;
            width: 200px;
            object-fit: cover;
            object-position: top;
            @media (max-width: 580px) {
                width: 170px;
            }
        }
    }
}
