// Navigation styles
.navigation {
  &__body {}
}

.navigation-list {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: white;
}

.navigation-item {
  padding: 0 15px;
  line-height: 1;
  @media (max-width: $md2+px) {
    padding: 0;
  }
  @media (max-width: $md2+px) {
    border-top: 1px solid #efefef;
    padding: 0;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  &__body {
  font-family: 'FuturaPT';
  font-weight: 500;
  padding: 15px 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 50%;
    width: 0px;
    border-bottom: 2px solid #ff9902;
    transition: all 0.3s ease-in-out;
  }
  &:hover:after {
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: 2px solid #ff9902;
  }
  }
  &__link {
    color: inherit;
    display: flex;
    align-items: flex-end;
    &_icon {
      font-size: 15px;
      margin: 5px 0 0 5px ;
      color: inherit;
    }
    &_text {
      @media (max-width: $md3+px) {
        font-size: 20px;
      }
    }
  }
}


// Dropdown styles
.dropdown {
  position: relative;
  @media (max-width: $md2+px) {
    margin-bottom: 15px;
  }
}

.dropdown-body {
  position: absolute;
  top: 10px;
  z-index: 3;
  box-shadow: 0 0 0 1px rgba(128,128,128,.5);
  background: rgba(0,0,0,.7);
  width: 230px;
}

.dropdown-list {
  margin: 5px 0;
  &__item {
    font-family: 'FuturaPT';
    font-weight: 300;
    padding: 5px 10px;
    @media (max-width: $md2+px) {
      padding: 8px 0 8px 20px;
    }
    &_link {}
    &_text {
      color: white;
      transition: padding-left 0.3s linear;
      @media (max-width: $md2+px) {
        font-size: 20px;
      }
      &:hover {
        padding-left: 5px;
        color: #ff9902;
      }
    }
  }
}


// Mobile-Navigation styles
.header-mobile-version {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  color: #000;
  z-index: 200;
  width: 100%;
  box-shadow: 0 0 0 1px rgba(128, 128, 128, 1);
  transition: 0.3s ease-out;
}

.header-mobile-version-menu {
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
  &__main {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 620px) {
      flex: 100%;
      flex-direction: row-reverse;
    }
    &_logo {
      position: absolute;
      top: 2px;
      left: 135px;
      @media (max-width: 620px) {
        left: 0;
      }
    }
    &_logo-img {
      height: 55px;
    }
    &_icon {
      width: 40px;
    }
  }

  &__links {
    flex: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &_phone {
      margin-right: 50px;
    }
    &_phone-number {
      color: black;
    }
    &_form {}
    &_form-button {
      border-radius: 25px;
      border-color: #ff9902;
      background: #ff9902;
      box-shadow: 1px 1px 1px #000;
    }
    &_form-text {}
  }
}

.mobile-navigation {
  position: relative;
  &__body {
    position: absolute;
    overflow-y: auto;
    height: 100vh;
    width: 400px;
    left: 0;
    top: 1px;
    z-index: 100;
    background: #fff;
    @media (max-width: 620px) {
      width: 100%;
    }
  }
  &__list {
    padding-bottom: 20px;
  }
  &__form-link {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
  &__phone-link {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
  }
}

.navigation-item-mobile-version {
  font-family: 'FuturaPT';
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 15px;
  &__link {
    color: inherit;
    &_text {
      font-size: 20px;
    }
  }
  &__icon {
    color: #3b3b3b;
    margin-right: 20px;
    @media (max-width: 620px) {
      margin-right: 25px;
    }
  }
}


///
#menuToggle {
  overflow: hidden;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: -13px;
  @media (max-width: 700px) {
    margin: -13px 0 0 0;
  }
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  opacity: 0; /* hide input */
  z-index: 1; /* top of the menu */
}

#menuToggle span{
  width: 35px;
  height: 2px;
  margin-bottom: 8px;
  background: #000;
  border-radius: 4px;
  -webkit-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -moz-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -ms-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  -o-transition: all .5s cubic-bezier(.08,.81,.87,.71);
  transition: all .5s cubic-bezier(.08,.81,.87,.71);
}

#span1 {
  transform-origin: 4px 0px;
}

#span3 {
  transform-origin: bottom right;
}

#menuToggle input:checked ~ #span1 {
  background-color: #ff9902;
  transform: rotate(45deg) translate(4px);
}
#menuToggle input:checked ~ #span2 {
  background-color: #ff9902;
  transform: rotate(495deg) translate(3px);
}
#menuToggle input:checked ~ #span3 {
  background-color: #ff9902;
  transform: rotate(45deg);
  opacity: 0;
}