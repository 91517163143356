.search-result {

  &__body {
    width: 100%;
  }
  &__title {
    margin-top: 250px;
    @media (max-width: 900px) {
      margin-top: 100px;
    }
    &_text {
      font-family: 'FuturaPT';
      font-weight: 700;
      font-size: 50px;
      text-align: center;
      @media (max-width: 900px) {
        font-size: 35px;
      }
    }
  }
  &__content {
    &_list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }
}

.search-list-item {
  flex: 0 0 25%;
  padding: 0 10px;
  margin: 30px 0;
  height: 250px;
  @media (max-width: $md1 + 'px') {
    flex: 0 0 33.333%;
  }
  @media (max-width: $md2 + 'px') {
    flex: 0 0 50%;
  }
  @media (max-width: $md3 + 'px') {
    flex: 100%;
    margin: 10px 0;
    height: 150px;
  }
  &__body {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    height: 100%;
    &:hover:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #4054b2;
      pointer-events: none;
      opacity: .5;
    }
  }
  &__link {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
  }
  &__title {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    &_text {
      color: white;
      font-size: 30px;
      text-shadow: 1px 1px 1px #000;
    }
  }
}