@font-face {
   font-family: 'FuturaPT-book';
   src: url('../assets/fonts/FuturaPT/futura-pt-book.eot');
   src: local('☺'), url('../assets/fonts/FuturaPT/futura-pt-book.woff') format('woff'), url('../assets/fonts/FuturaPT/futura-pt-book.ttf') format('truetype'), url('../assets/fonts/FuturaPT/futura-pt-book.svg') format('svg');
   font-weight: normal;
   font-style: normal;
}

@font-face {
  font-family: 'FuturaPT';
  src: url('../assets/fonts/FuturaPT/futura-pt-light.eot');
  src: local('☺'), url('../assets/fonts/FuturaPT/futura-pt-light.woff') format('woff'), url('../assets/fonts/FuturaPT/futura-pt-light.ttf') format('truetype'), url('../assets/fonts/FuturaPT/futura-pt-light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'FuturaPT';
  src: url('../assets/fonts/FuturaPT/futura-pt-medium.eot');
  src: local('☺'), url('../assets/fonts/FuturaPT/futura-pt-medium.woff') format('woff'), url('../assets/fonts/FuturaPT/futura-pt-medium.ttf') format('truetype'), url('../assets/fonts/FuturaPT/futura-pt-medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaPT';
  src: url('../assets/fonts/FuturaPT/futura-pt-bold.eot');
  src: local('☺'), url('../assets/fonts/FuturaPT/futura-pt-bold.woff') format('woff'), url('../assets/fonts/FuturaPT/futura-pt-bold.ttf') format('truetype'), url('../assets/fonts/FuturaPT/futura-pt-bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}


@font-face {
	font-family: 'Patetica';
	src: url('../assets/fonts/Patetica/Patetica.eot');
	src: local('☺'), url('../assets/fonts/Patetica/Patetica.woff') format('woff'), url('../assets/fonts/Patetica/Patetica.ttf') format('truetype'), url('../assets/fonts/Patetica/Patetica.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
