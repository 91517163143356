.links-section {
  width: 100%;
}

.links-section-list {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
  }
  &__item {
    @media (max-width: 900px) {
      padding-left: 0;
    }
    &_link{
      display: block;
      min-width: 100%;
      height: 100%;
    }
    &_icon svg {
      &:hover {
        color: #8b8b8b;
      }
    }
  }
}

