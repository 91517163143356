.lib {
    &__body {}
}

.lib-preview {
    height: 300px;
    max-width: 100%;
    background: url("../../assets/images/backgroundImages/librariesBackground.jpg") center/cover no-repeat fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
        &_text {
            color: #fff;
            text-shadow: 1px 1px 1px #000;
        }
    }
}

.lib-cards {}

.lib-list {
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $md1+px) {
        justify-content: space-around;
    }
    @media (max-width: 870px) {
        justify-content: space-between;
    }
    &__item {}
}

.lib-item {
    max-width: 270px;
    height: 400px;
    border: 1px solid #e9e9e9;
    margin: 30px 0px;
    @media (max-width: $md1+px) {
        max-width: 350px;
        height: auto;
    }
    @media (max-width: $md3 + 'px') {
        max-width: 300px;
        height: auto;
    }
    @media (max-width: 660px) {
        max-width: 270px;
        height: auto;
    }
    @media (max-width: 600px) {
        max-width: 250px;
        height: auto;
    }
    @media (max-width: 560px) {
        max-width: 350px;
        height: auto;
        margin: 20px auto;
    }
    &:hover {
        box-shadow: 0 0 15px 1px rgba(0,0,0,.3);
    }
    &__body {}

    &__head {
        margin: 10px;
        position: relative;
        &_img {
            width: 100%;
            height: 330px;
        }
        &_text {
            color: #000;
            font-size: 20px;
            font-weight: bold;
            margin: 20px 0 20px 10px;
        }
    }
    &__content {}
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &__text {
        color: #fff;
        font-size: 20px;
        font-weight: bold;
    }
    &:hover {
        opacity: 1;
    }
}

.lib-services {
    background: #f6f6f6;
}

.lib-services-list {
    &__item {
        padding: 8px 0;
        display: flex;
        align-items: center;
        &_text {
            padding-left: 20px;
        }
        &_icon {
            color: #ff9902;
            font-size: 13px;
        }
    }
}
