.about {
   &__body {}
 }


.about-links {
   &__body {}
}

.about-links-list {
  display: flex;
  margin: 0 -20px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.about-links-item {
  width: 100%;
  padding: 0 20px;
  @media (max-width: 900px) {
    padding: 10px 10px;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 15px;
    overflow: hidden;
    @media (max-width: 900px) {
      border-radius: 5px;
    }
    &:hover:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      pointer-events: none;
      opacity: .5;
    }
  }
  &__image {
    width: 100%;
    height: 250px;
    display: block;
    @media (max-width: 900px) {
      height: 100px;
    }
    &_img {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_title {
      color: #fff;
      font-size: 25px;
      text-shadow: 1px 1px 1px #000;
      z-index: 10;
    }
  }
}


.about-content {
  &__body {}
}

.about-main {
  &__title {
    &_text {}
  }
}

.about-main-content {
  display: flex;
  margin-top: 30px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  &__info {
    flex: 0 0 50%;
    padding-right: 20px;
    @media (max-width: 900px) {
      flex: 100%;
      padding-right: 0;
    }
    &_text {}
  }
  &__image {
    flex: 0 0 50%;
    width: 50%;
    @media (max-width: 900px) {
      flex: 100%;
      width: 100%;
      padding-top: 30px;
      height: 300px;
    }
    &_img {
      max-width: 100%;
      object-fit: cover;
      @media (max-width: 900px) {
        height: 100%;
        min-width: 100%;
      }
      @media (max-width: 380px) {
        object-position: right;
      }

    }
  }
}



////  About-Unification
.about-unification-content {
  margin-top: 30px;
  width: 100%;
  &__info {
    margin-bottom: 30px;
    width: calc(50% - 6px);
    @media (max-width: 900px) {
      width: 100%;
    }
    &_text {}
  }
  &__info-2 {
    margin-top: 30px;
    width: 100%;
    padding-left: calc(50% + 6px);
    @media (max-width: 900px) {
      padding-left: 0;
    }
    &_text {}
  }

}

.about-unification-image-section {
  display: flex;
  height: 600px;
  margin: 0 -3px;
  @media (max-width: 770px) {
    flex-wrap: wrap;
    height: auto;
  }
  &__block-1 {
    flex: 50%;
    @media (max-width: 770px) {
      flex: 100%;
    }
    &_img {
      max-width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      padding: 3px;
      @media (max-width: 770px) {
        height: auto;
        min-width: 100%;
      }
    }
  }
  &__block-2 {
    flex: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__image {
    max-width: 100%;
    height: 50%;
    &_img {
      padding: 3px;
      object-position: top;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}


////  About-Clubs
.about-clubs {
  &__body {}
  &__title {
    &_text {}
  }
  &__content {
    margin-top: 30px;
  }
}

.about-clubs-best {
  display: flex;
  flex-direction: column;
  &__info {
    width: 70%;
    @media (max-width: 900px) {
      width: 100%;
    }
    &_text {}
  }
  &__list {
    margin-top: 30px;
    padding: 0 20px;
    &_item {
      padding: 5px 0;
    }
    &_item-place {
      color: #a5240b;
      padding-left: 20px;
    }
  }
}

.about-clubs-other {
  margin-top: 30px;
  &__info {
    width: 70%;
    @media (max-width: 900px) {
      width: 100%;
    }
    &_text {}
  }
}


///// About-Libraries
.about-libraries {
  &__body {}
  &__title {
    &_text {}
  }
  &__info {
    &_text {}
  }
}

.about-libraries-content {
  margin-top: 30px;
  &__info {
    &_text {}
  }
  &__list {
    margin: 30px 0;
    padding: 0 20px;
    &_item {
      padding: 5px 0;
    }
  }
  &__description {
    &_text {}
  }
}


