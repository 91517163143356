.tinao {
  &__body {
    margin-top: 200px;
    @media (max-width: $md3 + 'px') {
      margin-top: 100px;
    }
  }
}

.tinao-preview {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1020px) {
    flex-direction: column;
    padding-top: 0;
  }
  &__logo {
    &_image {
      width: 550px;
      @media (max-width: $md1 + 'px') {
        width: 450px;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
  &__info {
    width: 500px;
    @media (max-width: 1020px) {
      margin-top: 70px;
      width: 90%;
    }
    @media (max-width: $md3 + 'px') {
      width: 100%;
    }
    &_title {
      font-family: 'FuturaPT';
      font-size: 35px;
      font-weight: 700;
      line-height: 1;
      color: #3b3b3b;
      position: relative;
      @media (max-width: 500px) {
        font-size: 30px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: -17px;
        left: 0;
        width: 150px;
        height: 3px;
        background: linear-gradient(55deg, rgba(45,116,71,1) 41%, rgba(243,224,58,1) 87%);
      }
    }
    &_text {
      font-family: 'FuturaPT';
      font-weight: 300;
      color: #6a6a6a;
      margin-top: 30px;
      font-size: 20px;
    }
  }
}

.tinao-events {
  margin-top: 250px;
  background-color: #31589d;
  color: #f2f3f4;
  position: relative;
  @media (max-width: 1020px) {
    margin-top: 200px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 24%;
    right: 20px;
    background: url('../../assets/images/10yearsTiNAO/decor/Asset6.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 1000px;
    height: 700px;
    z-index: 10;
    @media (max-width: 890px) {
      top: 26%;
      right: 0;
    }
    @media (max-width: 400px) {
      top: 28%;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 12%;
    left: 20px;
    background: url('../../assets/images/10yearsTiNAO/decor/Asset6.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 1000px;
    height: 700px;
    z-index: 10;
    transform: rotate(180deg);
    @media (max-width: 1020px) {
      bottom: 13%;
    }
    @media (max-width: 890px) {
      left: 0;
    }
  }
  &__body {
    padding-top: 50px;
    padding-bottom: 200px;
    position: relative;
    @media (max-width: 1020px) {
      margin-top: 0px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -200px;
      background: url('../../assets/images/10yearsTiNAO/decor/Asset9.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 500px;
      height: 700px;
      z-index: 10;
    }
  }
  &__wave-top {
    position: absolute;
    top: -437px;
    left: 0;
  }
  &__wave-bottom {
    position: absolute;
    bottom: -20px;
    left: 0;
  }
  &__preview {
    width: 500px;
    z-index: 100;
    position: relative;
    @media (max-width: 600px) {
      width: 100%;
    }
    &_title {
      font-family: 'FuturaPT';
      font-size: 35px;
      font-weight: 700;
      text-shadow: 1px 1px 1px #101010;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -17px;
        left: 0;
        width: 150px;
        height: 3px;
        background: linear-gradient(55deg, rgba(45,116,71,1) 41%, rgba(243,224,58,1) 87%);
      }
    }
    &_subtitle {
      font-family: 'FuturaPT';
      font-size: 20px;
      font-weight: 300;
      margin-top: 30px;
    }
  }
  &__content {
    position: relative;
    z-index: 100;
    &_list {}
  }
}

.tinao-item {
  padding: 150px 0;
  position: relative;
  @media (max-width: 1020px) {
    padding: 100px 0;
    height: auto;
    width: 60%;
  }
  @media (max-width: $md3 + 'px') {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    background:#ff9902;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__body {
    display: flex;
    height: 345px;
    @media (max-width: 1020px) {
      flex-direction: column;
      height: auto;
    }
  }
  &__decor {
    border-right: 2px solid #ff9902;
    border-bottom: 2px solid #ff9902;
    border-bottom-right-radius: 8px;
    padding-right: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 100;
    @media (max-width: $md3 + 'px') {
      border-right: none;
      border-bottom: none;
      padding: 0;
    }
    @media (max-width: 500px) {
      margin: 0 -20px;
    }
    &_img {
      width: 500px;
      border-radius: 5px;
      height: 100%;
      @media (max-width: $md1 + 'px') {
        width: 450px;
        object-fit: cover;
      }
      @media (max-width: 1020px) {
        width: 100%;
      }
      @media (max-width: $md3 + 'px') {
        border-radius: 0;
      }
    }
  }
  &__content {
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px 0;
    @media (max-width: $md1 + 'px') {
      margin-left: 40px;
    }
    @media (max-width: 1020px) {
      margin-left: 0;
    }
    @media (max-width: $md3 + 'px') {
      border-right: none;
      border-bottom: none;
      padding-top: 50px;
    }
  }
  &__title {
    &_value {
      font-family: 'FuturaPT';
      font-size: 25px;
      font-weight: 700;
      text-shadow: 1px 1px 1px #101010;
      @media (max-width: 500px) {
        font-size: 22px;
      }
    }
  }
  &__info {
    margin-top: 30px;
    margin-right: 30px;
    @media (max-width: 500px) {
      margin-right: 0;
    }
    &_text {
      font-family: 'FuturaPT';
      font-size: 18px;
      line-height: 1.4;
      font-weight: 300;
    }
  }
  &__links {
    display: flex;
    align-items: flex-end;
    justify-self: flex-end;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__date {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-right: 70px;
    @media (max-width: 500px) {
      margin-right: 0;
      margin-top: 50px;
    }
    &_icon {}
    &_value {
      font-family: 'FuturaPT';
      font-size: 18px;
      font-weight: 700;
      margin-left: 20px;
      text-shadow: 1px 1px 1px #101010;
    }
  }
  &__document {
    margin-top: 50px;
    width: 130px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #ff9902;
    background: linear-gradient(20deg, rgba(49,88,157,1) 39%, rgba(68,161,209,1) 85%);
    box-shadow: 0 0 5px #44a1d1, inset 0 1px 8px -2px #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &_link {
      padding-bottom: 5px;
    }
    &_text {
      color: #f2f3f4;
      text-transform: uppercase;
      font-family: 'FuturaPT';
      font-size: 12px;
      font-weight: 700;
      text-shadow: 1px 1px 1px #101010;
    }
  }
}