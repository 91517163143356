.page-preview {
  height: 100vh;
  max-width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  @media (max-width: 1000px) {
    background-attachment: scroll;
  }
  &__body {
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__content {
    position: absolute;
    top: 45%;
    left: 0;
    bottom: 0;
    right: 0;
    @media (max-width: 600px) {
      top: 30%;
    }
  }
}

.page-preview-block {
  max-width: 100%;
  height: 100%;
}

.page-preview-title {
  &__text {
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    @media (max-width: $md2 + 'px') {
      font-size: 40px;
    }
    @media (max-width: 370px) {
      font-size: 30px;
    }
  }
}

.page-preview-navigation {
  font-family: 'FuturaPT';
  font-weight: 700;
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    flex-wrap: wrap;
    &_link {
      color: #C0C0C0;
      text-shadow: 1px 1px 1px #000;
      font-size: 20px;
      padding-left: 40px;
      @media (max-width: $md1 + 'px') {
        padding-top: 30px;
      }
      @media (max-width: $md3 + 'px') {
        text-align: center;
      }
      @media (max-width: 370px) {
        font-size: 17px;
      }
      &:first-child {
        padding-right: 0px;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}

.active {
  color: #fff;
}