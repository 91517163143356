.closed {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.opened {
  opacity: 1;
  .vertical {
    background-color: #ff9902;
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.circle-plus {
  font-size: 1em;
}

.circle {
  position: relative;
  width: 30px;
  height: 30px;
}
.horizontal {
  position: absolute;
  background-color: #000;
  width: 20px;
  height: 3px;
  left: 50%;
  margin-left: -10.5px;
  top: 50%;
  margin-top: -2.5px;
}
.vertical {
  position: absolute;
  background-color: #000;
  width: 3px;
  height: 20px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -10px;
}