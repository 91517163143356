.fixed-navigation {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(128,128,128,.5);
}

.fixed-navigation div {
  color: black;
  background: white;
}

.fixed-navigation span {
  color: black;
}

.con {
  position: relative;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background: rgba(0,0,0,.7)
}

.header-main {
  width:100% ;
  box-shadow: 0 0 0 1px rgba(128,128,128,.5);
}

.header-info-body {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 70px;
}

.header-info-logo {  
  &__img {
    display: block;
    height: 70px;
    padding-top: 10px;
  }
}

.header-info-search {
  width: 100%;
  position: relative;
  line-height: 1;
  margin: 0 30px;
  &__img {
    position: absolute;
    right: 0px;
    bottom: 10px;
    z-index: 1;
    cursor: pointer;
    
    &:hover {
      color: #ff9902;
    }
  }
  &__input {
    border-bottom: 2px solid #fff;
    color: white;
    font-size: 15px;
  }
}

.mobile-info-search {
  width: 100%;
  padding: 0 10px 35px;
  position: relative;
  &__img {
    position: absolute;
    right: 10px;
    top: 15px;
  }
  &__input {
    border-bottom: 2px solid #000;
    color: #000;
  }
}

.header-info-contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  

.info-glasses {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  // @media (max-width: 970px) {
  //   margin-right: 10px;
  // }
  &__icon {
    cursor: pointer;
    &:hover {
      color: #ff9902;
    }
  }
}

.info-glasses__text {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 3px 0;
  color: white;
  @media (max-width: 970px) {
    margin-left: 5px;
  }
}

//
.header-info-forms {
  &__button {
    border: 2px solid #fff;
    &_text {
      color: white;
    }
  }
}
//

.scroll-arrow {
  background: #ff9902;
  position: fixed;
  top: 50vh;
  right: 15px;
  z-index: 200;
  padding: 10px 15px;
  border-radius: 50%;
  line-height: 1;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  &:hover {
    border-radius: 0;
    width: 80px;
    height: 100vh;
    right: 0;
    top: 0;
    padding: 52vh 30px 0;
    transition:  background-color .6s linear,height .3s linear,width .3s linear;
  }
}
.scroll-arrow-icon {
  font-size: 30px;
  color: white;
}

