.header-media-section {
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
  }
  &__links {
    min-width: 200px;
  }
}

.info-line {
  &__body {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 10px;
    color: #fff;
  }
}

.info-phones {
  display: flex;
  align-items: center;
  padding-right: 30px;
  cursor: pointer;
  @media (max-width: $md1+px) {
    padding-right: 20px;
  }
  @media (max-width: $md2+px) {
    padding-right: 10px;
  }
  &__link {
    color: #fff;
    padding-left: 5px;
  }
}

.info-location {
  display: flex;
  align-items: center;
  padding-right: 30px;
  cursor: pointer;
  @media (max-width: $md1+px) {
    padding-right: 20px;
  }
  @media (max-width: $md2+px) {
    padding-right: 10px;
  }
  &__link {
    padding-left: 5px;

  }
}

.info-mail {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__link {
    padding-left: 5px;
    color: #fff;
  }
}