.contacts {
    &__body {}
}

.contacts-switch-panel {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);
    border-top: 1px solid rgba(128, 128, 128, 0.5);;
    &__body {}
}

.contacts-switch-list {
    font-family: 'FuturaPT';
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__item {
        flex: 33.333%;
        margin: 10px 0px;
        cursor: pointer;
        @media (max-width: $md2 + 'px') {
            flex: 50%;
        }
        @media (max-width: 570px) {
            flex: 100%;
        }
        &_active {
            border-radius: 20px;
            border-color: #ff9902;
            background: #ff9902;
            transition: .2s ease-out;
            box-shadow: 1px 1px 1px #000;
        }
    }
    &__item p {
        padding: 10px 10px;
        text-align: center;
    }
}

/////////

.contacts-item {
    &__body {
        background: #f6f6f6;
    }
}
.contacts-content-body {
    border-bottom: 1px solid rgba(128, 128, 128, 0.5);;
}
.contacts-container {}

.contacts-content {
    display: flex;
    align-items: flex-start;
    @media (max-width: $md2 + 'px') {
        display: block;
    }
}

.contacts-form {
    flex: 50%;
    @media (max-width: $md2 + 'px') {
        width: 80%;
        margin: 40px auto;
    }
    @media (max-width: $md3 + 'px') {
        width: 100%;
    }
}

.contacts-info {
    height: 100%;
    flex:0 0 50%;
    padding-right: 30px;
    @media (max-width: $md2 + 'px') {
        padding-right: 0;
        width: 80%;
        margin: 0px auto;
    }
    @media (max-width: $md3 + 'px') {
        width: 100%;
    }
    &__title {
        font-size: 30px;
        &_text {}
    }
    &__block {
        margin-top: 20px;
        line-height: 1.3;
        &_title {
            font-weight: bold;
        }
        &_text {}
    }
}

.contacts-uchred {
    background: #f6f6f6;
    &__body {}
    &__title {
        padding-top: 50px;
        font-size: 30px;
        @media (max-width: $md3 + 'px') {
            padding-top: 30px;
        }
    }
    &__info {
        padding: 20px 0;
        padding-bottom: 50px;
        @media (max-width: $md3 + 'px') {
            padding-bottom: 30px;
        }
    }
    &__info-block {
        padding: 10px 0;
        &_title {
            font-weight: bold;
        }
        &_info {
            color: #000;
            font-size: 16px;
        }
    }
}