.leto {
  position: relative;
  z-index: 10;
  &__body {
    
  }
  &__content {
    padding-top: 250px;
    @media (max-width: 900px) {
      padding-top: 100px;
    }
  }
}

.leto-info {
  &__title {
    &_text {
      font-size: 40px;
      font-family: 'Patetica';
      @media (max-width: 900px) {
        font-size: 35px;
      }
      @media (max-width: 500px) {
        font-size: 30px;
      }
    }
  }
  &__subtitle {
    margin-top: 30px;
    width: 60%;
    @media (max-width: 500px) {
      width: 100%;
    }
    &_text {
      font-size: 20px;
      color: #3b3b3b;
      padding-bottom: 20px;
    }
  }
}

.leto-programes {
  display: flex;
  margin-top: 50px;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
}


.leto-program {
  flex: 50%;
  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title {
    &_text {
      font-family: 'Patetica';
      font-size: 30px;
    }
  }
  &__subtitle {
    margin-top: 20px;
    &_text {
      font-size: 20px;
      color: #3b3b3b;
    }
  }
}

.test-block {
  margin-top: 100px;
  &__body {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-top: 3px solid #3b3b3b;
    border-right: 3px solid #3b3b3b;
    border-bottom: 3px solid #3b3b3b;
    border-left: 3px solid #3b3b3b;
    border-radius: 50%;
    position: relative;
  }
  &__list {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.test-block-item {
  position: absolute;
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(255, 153, 2, 1);
  animation: pulse 3s infinite;
  &:hover {
    animation: none;
  }
  &__icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &_ico {}
  }
  &__text {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_number {
      font-weight: 700;
    }
    &_value {
      font-size: 18px;
      color: #3b3b3b;
      font-weight: 700;
    }
  }
}

.leto-program-button {
  margin-top: 100px;
  &__body {
    border: 2px solid #000; 
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    width: 100%;
    height: 100%;
    &_text {}
  }
}

._1 {
  top: 0 - (50px);
  left: 50% - (30 / 2);
}

._2 {
  left: -50px;
  top: 50% - (30 / 2);;
}

._3 {
  right: -50px;
  top: 50% - (30 / 2);;
}

._4 {
  bottom: 0 - (50px);
  left: 50% - (30 / 2);;
}


// Animation
.show-text-enter {
  opacity: 0;
}

.show-text-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.show-text-exit {
  opacity: 1;
}

.show-text-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}