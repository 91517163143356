.full-screen {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    min-height: auto;
    margin-top: 60px;
  }
  &__vidio {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 900px) {
      object-fit: contain;
      height: auto;
      position: static;
    }
  }
}