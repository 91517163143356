.carousel {
  position: relative;
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
  &__item {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: ease-in-out 1.7s;
    &_prev {
      transform: translateX(-100%);
      opacity: 0;
    }
    &_next {
      transform: translateX(100%);
      opacity: 0;
    }
  }
}


.carousel-item {
  min-width: 100%;
  height: 100%;
  text-align: center;
  &__body {}
  &__title {
    font-family: 'FuturaPT';
    font-weight: 700;
    margin-bottom: 30px;
    &_text {
      color: #fff;
      font-size: 40px;
      text-shadow: 1px 1px 1px #000;
      @media (max-width: $md2 + 'px') {
        font-size: 30px;
      }
      @media (max-width: 370px) {
        font-size: 25px;
      }
      @media (max-height: 400px) {
        font-size: 20px;
      }
    }
  }
  &__subtitle {
    margin-bottom: 30px;
    &_text {
      text-shadow: 1px 1px 1px #000;
      color: #fff;
      font-size: 25px;
      @media (max-height: 400px) {
        font-size: 20px;
      }
    }
  }
  &__link {
    margin: 0 auto;
    border: 2px solid white;
    box-shadow: 1px 1px 1px #000;
    &_body {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: 100%;
      text-shadow: 1px 1px 1px #000;

    }
    &_text {}
  }
}
