.contacts-form {
  width: 100%;
}

.form-wrapper {
  min-width: 100%;
  min-height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20000;
  overflow: auto;
}

.form-body {
  position: absolute;
  max-width: 700px;
  height: auto;
  top: calc((100% / 2) - 187.5px);
  left: calc((100% / 2) - 350px);
  background: #f6f6f6;
  @media (max-width: 900px) {
    max-width: 100%;
    left: 0;
  }
}

.form-content-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 0 35px 35px;
  @media (max-width: 730px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    padding: 0 20px 50px;
  }
}

.form-icon {
  display: flex;
  justify-content: flex-end;
  &__body {
    display: inline-block;
    cursor: pointer;
    z-index: 10000000;
    &_ico {
      font-size: 25px;
    }
  }
}

.form-title {
  &__text {}
}

.form-main {
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-input {
  border-bottom: 2px solid #000;
}

label {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
}

.form-submit {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.form-answer {
  color: #ff9902;
  font-size: 15px;
  margin-left: 30px;
}

.form-button {

  border: 2px solid #000;
}

.cl-btn-3 {
  width: 15px;
  height: 15px;
  margin: 20px 20px 0 0;
  position: relative;
  transform: rotate(45deg) scale(2);
}

.cl-btn-3 .top,
.cl-btn-3 .bot {
  display: block;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  animation-timing-function: cubic-bezier(1, 0, 0, 1);
  animation-iteration-count: 1;
}

.cl-btn-3 .top {
  height: 100%;
  width: 1px;
  margin-left: -1px;
  top: 0;
}

.cl-btn-3 .bot {
  width: 100%;
  height: 1px;
  margin-top: -1px;
  left: 0;
}

@-webkit-keyframes top-out {
  from {
    top: 0%;
    height: 100%;
  }
  to {
    top: 200%;
    height: 0%;
  }
}

@keyframes top-out {
  from {
    top: 0%;
    height: 100%;
  }
  to {
    top: 200%;
    height: 0%;
  }
}
@-webkit-keyframes top-in {
  from {
    top: -100%;
    height: 0%;
  }
  to {
    top: 0%;
    height: 100%;
  }
}
@keyframes top-in {
  from {
    top: -100%;
    height: 0%;
  }
  to {
    top: 0%;
    height: 100%;
  }
}
@-webkit-keyframes bot-out {
  from {
    left: 0%;
    width: 100%;
  }
  to {
    left: 200%;
    width: 0%;
  }
}
@keyframes bot-out {
  from {
    left: 0%;
    width: 100%;
  }
  to {
    left: 200%;
    width: 0%;
  }
}
@-webkit-keyframes bot-in {
  from {
    left: -100%;
    width: 0%;
  }
  to {
    left: 0%;
    width: 100%;
  }
}
@keyframes bot-in {
  from {
    left: -100%;
    width: 0%;
  }
  to {
    left: 0%;
    width: 100%;
  }
}
.cl-btn-3:hover {
  cursor: pointer;
}
.cl-btn-3:hover .top {
  animation: top-out 0.25s forwards, top-in 0.25s forwards 0.25s;
  @media (max-width: 900px) {
    animation: none;
  }

}
.cl-btn-3:hover .bot {
  animation: bot-out 0.25s forwards 0.1s, bot-in 0.25s forwards 0.35s;
  @media (max-width: 900px) {
    animation: none;
  }
}


